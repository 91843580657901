import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { deletecust, listcust } from "../../../../services/customer";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import imageUrl from "../../../../ImageUrl";

export const ListBuyer = () => {
  const gridRef = useRef();
  const navigate = useNavigate();
  

  const columnDefs = [
    {
      headerName: "Action/செயல்",
      //headerTooltip "Action",
      width: 90,
      field: "id",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "center" },

      cellRenderer: function (params) {
        return (
          <a
            className="btn-sm btn-info"
            onClick={() => navigate(`/superadmin/viewcompany/${params.value}`)}
          >
            <i className="fa fa-eye fa-lg"></i>
          </a>
        );
      },
    },
    {
      headerName: "Photo",
      //headerTooltip "Photo",
      width: 90,
      field: "photo",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value != null){
          return (
          <img src={imageUrl + path + params.value} width={"50px"} height={"50px"} onError={(e) => errorhandle(e)}/>
          );
        }
        
      },
    },
    {
      headerName: "Company Name/நிறுவனத்தின் பெயர்",
      //headerTooltip "Company Name",
      width: 400,
      field: "name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "நிறுவனத்தின் பெயர்",
    //   //headerTooltip "நிறுவனத்தின் பெயர்",
    //   width: 230,
    //   field: "tamil_name",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Address/முகவரி",
      //headerTooltip "Address",
      width: 250,
      field: "area",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "முகவரி",
    //   //headerTooltip "முகவரி",
    //   width: 110,
    //   field: "tamil_area",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Mobile/தொலைபேசி எண்",
      //headerTooltip "Mobile",
      width: 130,
      field: "phone",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Status",
      //headerTooltip "Status",
      width: 90,
      field: "active",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellRenderer: function (params) {
        return (
          <div className="text-center">
            <label className="custom-switch mb-0">
              <input
                type="checkbox"
                name="custom-switch-checkbox"
                className="custom-switch-input"
                checked={params.value == 1 ? true : false}
                onChange={() => deletecustomer(params.value, params)}
              />

              <span className="custom-switch-indicator"></span>
            </label>
          </div>
        );
      },
    },
   
  ];

  function deletecustomer(list, lvalue) {
    if (list == 1) {
      Swal.fire({
        text: "Are you sure want to Inactive?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ac0404",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Inactive",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", lvalue.data.id);
          temp.append("status", 0);

          deletecust(temp).then((response) => {
            if (response.status == true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    } else {
      Swal.fire({
        text: "Are you sure want to Active?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#6259ca",
        confirmButtonText: "Active",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const temp = new FormData();
          temp.append("id", lvalue.data.id);
          temp.append("status", 1);

          deletecust(temp).then((response) => {
            if (response.status == true) {
              listall();
              toast.success(response.message);
            }
          });
        }
      });
    }
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/img/user.png");
    ev.target.onerror = null;
  }

  const [filterProduct, setFilterProduct] = useState([]);
  useEffect(() => {
    listall();
  }, []);
  const [path, setPath] = useState("");
  function listall() {
    setFilterProduct([]);
    listcust(1).then((res) => {
      if (res.status == true) {
        setFilterProduct(res.data.details);
        setPath(res.data.path);
      }
    });
  }
  const downloadexcel = useCallback(() => {
    const params = {
      skipHeader: false,
      columnGroups: true,
      skipFooters: true,
      skipGroups: false,
      skipPinnedTop: true,
      skipPinnedBottom: true,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      fileName: "export",
      sheetName: "Sheet1",
      exportMode: "pdf", // Change this to 'pdf' for PDF export
      // Optional PDF export specific properties
      pdfPageSize: "A4",
      pdfOrientation: "portrait",
      processRowPostCreate: function (row) {
        // Optional callback for PDF export to customize row styling
        // e.g., row.node.data.price > 50000 ? { backgroundColor: 'red' } : null
        return null;
      },
    };
    // gridRef.api.exportDataAsCsv(params); // Change to exportDataAsPdf for PDF export
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-20">
          Company List / நிறுவனத்தின் பட்டியல்
        </h2>

        <Row className="row-sm mx-0 mt-3">
          <Col xxl={12} lg={12} md={12}>
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={filterProduct}
                pagination="true"
                paginationPageSize="10"
                suppressExcelExport={true}
                suppressDragLeaveHidesColumns={true}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
