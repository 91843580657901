import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Login from "./components/login/login";
import Network from "./components/Network";
import { AddCustomer } from "./components/Dashboard/superadmin/customer/addcustomer";
import { ListCustomer } from "./components/Dashboard/superadmin/customer/listcustomer";
import { AddDelivery } from "./components/Dashboard/superadmin/delivery/adddelivery";
import { ListDelivery } from "./components/Dashboard/superadmin/delivery/listdelivery";
import { ViewCustomer } from "./components/Dashboard/superadmin/customer/viewcustomer";
import { ViewDelivery } from "./components/Dashboard/superadmin/delivery/viewdelivery";
import { Profile } from "./components/Dashboard/profile";
import { AddBuyer } from "./components/Dashboard/superadmin/buyer/addbuyer";
import { ListBuyer } from "./components/Dashboard/superadmin/buyer/listbuyer";
import { ViewBuyer } from "./components/Dashboard/superadmin/buyer/viewbuyer";
import { BuyerAddDelivery } from "./components/Dashboard/superadmin/buyerdelivery/buyeradddelivery";
import { BuyerListDelivery } from "./components/Dashboard/superadmin/buyerdelivery/buyerlistdelivery";
import { BuyerViewDelivery } from "./components/Dashboard/superadmin/buyerdelivery/buyerviewdelivery";
import { DateWiseReport } from "./components/Dashboard/Datewisereport";
import { BuyerListPending } from "./components/Dashboard/superadmin/buyerdelivery/buyerpending";
import { DeliveryListPending } from "./components/Dashboard/superadmin/delivery/deliverypending";
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const App = React.lazy(() => import("./components/app"));

const Root = () => {
  return (
    <Fragment>
      <HashRouter>
        <React.Suspense fallback={<Loader />}>
          <Network />
          <Routes>
            <Route>
              <Route path={`/`} element={<Navigate to="/login" />} />
              <Route path={`/login`} exact={true} element={<Login />} />

            </Route>

            <Route element={<App />}>
              <Route path={`/superadmin/dashboard`} element={<Dashboard />} />
              <Route path={`/superadmin/profile`} element={<Profile />} />

              <Route path={`/superadmin/datewisereport/:id/:type`} element={<DateWiseReport />} />

              <Route path={`/superadmin/addcustomer`} element={<AddCustomer />} />
              <Route path={`/superadmin/customerlist`} element={<ListCustomer />} />
              <Route path={`/superadmin/viewcustomer/:id`} element={<ViewCustomer />} />

              <Route path={`/superadmin/addcompany`} element={<AddBuyer />} />
              <Route path={`/superadmin/companylist`} element={<ListBuyer />} />
              <Route path={`/superadmin/viewcompany/:id`} element={<ViewBuyer />} />

              <Route path={`/superadmin/addcompanybill`} element={<BuyerAddDelivery />} />
              <Route path={`/superadmin/companybilllist`} element={<BuyerListDelivery />} />
              <Route path={`/superadmin/viewcompanybill/:id`} element={<BuyerViewDelivery />} />
              <Route path={`/superadmin/companypending`} element={<BuyerListPending />} />



              <Route path={`/superadmin/adddelivery`} element={<AddDelivery />} />
              <Route path={`/superadmin/deliverylist`} element={<ListDelivery />} />
              <Route path={`/superadmin/viewdelivery/:id`} element={<ViewDelivery />} />
              <Route path={`/superadmin/deliverypending`} element={<DeliveryListPending />} />

              <Route path={`/manager/dashboard`} element={<Dashboard />} />
              <Route path={`/manager/profile`} element={<Profile />} />
              <Route path={`/manager/adddelivery`} element={<AddDelivery />} />
              <Route path={`/manager/deliverylist`} element={<ListDelivery />} />
              <Route path={`/manager/viewdelivery/:id`} element={<ViewDelivery />} />
              <Route path={`/manager/deliverypending`} element={<DeliveryListPending />} />
             
            </Route>
          </Routes>
        </React.Suspense>
      </HashRouter>
    </Fragment>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
