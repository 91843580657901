import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function createdel(data) {
  try {
    return await axios
      .post(`${baseUrl}/createdelivery`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function updatedel(data) {
    try {
      return await axios
        .post(`${baseUrl}/updatedelivery`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  
export async function listdel() {
    try {
      return await axios
        .get(`${baseUrl}/deliverylist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function viewdel(id) {
    try {
      return await axios
        .get(`${baseUrl}/viewdelivery/`+id)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  
export async function listbroilertype() {
    try {
      return await axios
        .get(`${baseUrl}/broilertypelist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


  
export async function deletebroilertype(did) {
  try {
    return await axios
      .get(`${baseUrl}/broilertypedelete/`+did)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function filterdelivery(cid,did,tid,type) {
  try {
    return await axios
      .get(`${baseUrl}/delivery-pdf-view/`+cid+`/`+did+`/`+tid + `/`+type)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function listamounttype() {
  try {
    return await axios
      .get(`${baseUrl}/amounttypelist`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function deleteamounttype(did) {
  try {
    return await axios
      .get(`${baseUrl}/amounttypedelete/`+did)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function viewdelpending(id) {
  try {
    return await axios
      .get(`${baseUrl}/viewdeliverypendingdetail/`+id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function deletedelpending(data) {
  try {
    return await axios
      .post(`${baseUrl}/deletedeliverypending`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response)
  if (error.response.status == 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status == 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status == 0) {
    toast.error(error.message);
  } else if (error.response.status == 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status != 200 &&
    error.response.data.status == false &&
    error.response.data.code != 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
