import { useNavigate } from "react-router-dom";
import "./loginnew.css";
import { useState } from "react";
import { logindata } from "../../services/login";
const Login = () => {
  const [user, setUsername] = useState("");
  const [pass, setPassword] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();

  function submit(e) {
    e.preventDefault();
    setErrormsg("");
    setLogged(true);
    if (user != "" && pass != "") {
      setLogged(false);
      const temp = new FormData();
      temp.append("email", user);
      temp.append("password", pass);
      logindata(temp).then((res) => {
        if (res.status == true) {
          localStorage.setItem("UID", res.data.id);
          localStorage.setItem("Name", res.data.name);
          localStorage.setItem("Role", res.data.role);
          if (res.data.role == "1") {
            navigate("/superadmin/dashboard");
          }else if (res.data.role == "2") {
            navigate("/manager/dashboard");
          }
        }else {
          setErrormsg(res.message);
        } 
      });
    }
  }
  return (
    <div id="loginpage">
      <div className="page_loader"></div>
      <div className="login-2 login-background">
        <div className="login-2-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-section align-self-center">
                  <div className="logo-1 logoimg">
                    <a>
                      <img
                        src={require("../../assets/img/Venkateshwara.png")}
                        alt="logo"
                      />
                    </a>
                  </div>
                  <h3>Login Into Your Account</h3>

                  <div className="clearfix"></div>
                  <div className="col-md-12"></div>
                  <form>
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        className="form-control"
                        id="username"
                        name="username"
                        placeholder="Username"
                        type="text"
                        value={user}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      {logged && user == "" ? (
                        <span className="text-danger">Please Enter Username</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group clearfix">
                      <input
                        autoComplete="off"
                        className="password-input form-control"
                        data-val="true"
                        data-val-required="Please Provide Password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                        value={pass}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {logged && pass == "" ? (
                        <span className="text-danger">Please Enter Password</span>
                      ) : (
                        ""
                      )}
                    </div>
                    {errormsg != "" ? (
                      <span className="text-danger">{errormsg}</span>
                    ) : (
                      ""
                    )}

                    <div className="form-group clearfix mt-3">
                      <button
                        id="login-button"
                        type="submit"
                        className="btn btn-lg btn-primary btn-theme"
                        onClick={submit}
                      >
                        Login
                      </button>

                      {/* <!-- <a href="forgot-password-2.html" className="forgot-password float-end">Forgot Password</a> --> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
