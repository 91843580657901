import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { daywisedetails } from "../../services/login";

export const DateWiseReport = () => {
  const { id, type } = useParams();
  const [filterProduct, setFilterProduct] = useState([]);

  const columnDefs = [
    {
      headerName: "Date/தேதி",
      //headerTooltip "Date/தேதி",
      field: "delivery_date",
      width: 130,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Company/நிறுவனம்",
      //headerTooltip "Company",
      field: "name",
      width: 320,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
     
    },
    // {
    //   headerName: "நிறுவனம்",
    //   //headerTooltip "நிறுவனம்",
    //   field: "tamil_name",
    //   width: 160,
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
      
    // },
    {
      headerName: "Kilo/கிலோ",
      //headerTooltip "Kilo/கிலோ",
      field: "total_kgs",
      width: 120,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount/தொகை",
      //headerTooltip "Amount/தொகை",
      field: "total_amount",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Paid/கொடுத்தது",
      //headerTooltip "Paid/கொடுத்தது",
      field: "total_received",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pending/நிலுவை",
      //headerTooltip "Pending/நிலுவை",
      field: "total_pending",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  const columnCDefs = [
    {
      headerName: "Date/தேதி",
      //headerTooltip "Date/தேதி",
      field: "delivery_date",
      width: 130,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Customer/வாடிக்கையாளர்",
      //headerTooltip "Customer",
      field: "name",
      width: 320,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "வாடிக்கையாளர்",
    //   //headerTooltip "வாடிக்கையாளர்",
    //   field: "tamil_name",
    //   width: 160,
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Kilo/கிலோ",
      //headerTooltip "Kilo/கிலோ",
      field: "total_kgs",
      width: 120,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount/தொகை",
      //headerTooltip "Amount/தொகை",
      field: "total_amount",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Received/பெற்றது",
      //headerTooltip "Received/பெற்றது",
      field: "total_received",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pending/நிலுவை",
      //headerTooltip "Pending/நிலுவை",
      field: "total_pending",
      width: 160,
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  useEffect(()=>{
    setFilterProduct([]);
    daywisedetails(id,type).then((res)=>{
        if(res.status == true){
            setFilterProduct(res.data)
        }
    })
  },[])
  const getRowStyle = params => {
    if (params.data && params.data.type == 1) { 
        return { background: '#ceeba0' }; // Change the background color as desired
    }
    return null;
};

  return (
    <div className="page-header">
      {type == "1" && (
        <h2 className="main-content-title tx-20">DateWise Company Report / தேதி வாரியாக நிறுவனத்தின் அறிக்கை</h2>
      )}
      {type == "2" && (
        <h2 className="main-content-title tx-20">DateWise Customer Report / தேதி வாரியான வாடிக்கையாளர் அறிக்கை</h2>
      )}

      <div className="table-responsive px-2">
        <div className="ag-theme-alpine ag-style">
          <AgGridReact
            columnDefs={type == "1" ? columnDefs : columnCDefs}
            getRowStyle={getRowStyle}
            rowData={filterProduct}
            pagination="true"
            paginationPageSize="10"
            suppressExcelExport={true}
            suppressDragLeaveHidesColumns={true}
          />
        </div>
      </div>
    </div>
  );
};
