import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import {
  createcust,
  custvalidatename,
  custvalidatephone,
  custvalidatetamil,
  listtypeactive,
} from "../../../../services/customer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
export const AddCustomer = () => {
  const [name, setName] = useState("");
  const [tlist, setTList] = useState([]);

  const [tname, setTName] = useState("");
  const [area, setArea] = useState("");
  const [tarea, setTArea] = useState("");
  const [phone, setPhone] = useState("");
  const [pfile, setPFile] = useState("");

  const [errormsg, setErrormsg] = useState("");
  const [logged, setLogged] = useState(false);
  const [photoerror, setPhotoError] = useState("");
  const navigate = useNavigate();

  function photofile(event) {
    setPhotoError("");
    setPFile("");
    if (event.target.files.length != 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPFile(event.target.files[0]);
      } else {
        setPhotoError("Photo should be jpg, jpeg, or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoError("Photo size should be less than 5MB");
      }
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const [valmob, setValMob] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    var obj = {};
    if (e.target.value != "") {
      var check="";
      if (e.target.value.length != 10) {
        check = "Mobile Number must be 10 characters";
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] == 10) {
        check = "Enter a Valid Mobile Number";
        setValMob("Enter a Valid Mobile Number");
      }

      if (check == "") {
        const temp = new FormData();
        temp.append("name", e.target.value);
        temp.append("type", 2);
        custvalidatephone(temp).then((res) => {
          if (res.status == false) {
            setValMob(res.message);
          }
        });
      }
    }
  }

  function submitcust(e) {
    e.preventDefault();
    setLogged(true);
    // setValMob("");
    setErrormsg("");
    var check = "";
    // if (phone != "") {
    //   if (phone.length != 10) {
    //     check = "Mobile Number must be 10 characters";
    //     setValMob("Mobile Number must be 10 characters");
    //   }
    //   var obj = {};
    //   for (let x = 0, length = phone.length; x < length; x++) {
    //     var l = phone.charAt(x);
    //     obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
    //   }

    //   if (obj[l] == 10) {
    //     check = "Enter a Valid Mobile Number";
    //     setValMob("Enter a Valid Mobile Number");
    //   }
    // }

    if (
      name != "" &&
      // tname != "" &&
      area != "" &&
      // tarea != "" &&
      phone != "" &&
      valname == "" &&
      // valtamil == "" &&
      photoerror == "" &&
      check == ""
    ) {
      setLogged(false);
      setLoading(true);
      const temp = new FormData();
      temp.append("name", name);
      temp.append("tamilname", "");//tname
      temp.append("area", area);
      temp.append("tamilarea", "");//tarea
      temp.append("phone", phone);
      temp.append("type", 2);
      temp.append("photo", pfile);
      temp.append("user_id", localStorage.getItem("UID"));

      createcust(temp).then((res) => {
        if (res.status == true) {
          setLoading(false);
          toast.success(res.message);
          navigate("/superadmin/customerlist");
        }
      });
    }
  }

  useEffect(() => {
    listtypeactive().then((response) => {
      if (response.status == true) {
        setTList(response.data);
      }
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const [valname, setValName] = useState("");
  function checkname(e) {
    e.preventDefault();
    setValName("");
    if (e.target.value != "") {
      const temp=new FormData();
      temp.append('name',e.target.value)
      temp.append('type',2)
      custvalidatename(temp).then((res) => {
        if (res.status == false) {
          setValName(res.message);
        }
      });
    }
  }

  const [valtamil, setValTamil] = useState("");
  function checktamil(e) {
    e.preventDefault();
    if (e.target.value != "") {
      const temp=new FormData();
      temp.append('name',e.target.value)
      temp.append('type',2)

      custvalidatetamil(temp).then((res) => {
        if (res.status == false) {
          setValTamil(res.message);
        }
      });
    }
  }

  return (
    <div className="page-header">
      {loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}
      {!loading &&
<>
<h2 className="main-content-title tx-20">
        Add Customer / வாடிக்கையாளர் சேர்த்தல்
      </h2>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <Row className="row-sm mx-0">
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Customer Name/வாடிக்கையாளர் பெயர் <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Customer Name"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      onBlur={checkname}
                      value={name}
                    />
                    {logged && name == "" ? (
                      <span className="text-danger">Please Enter Name</span>
                    ) : (
                      ""
                    )}

                    {name && valname != "" ? (
                      <span className="text-danger">{valname}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {/* <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        வாடிக்கையாளர் பெயர்{" "}
                        <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"வாடிக்கையாளர் பெயர்"}
                      value={tname}
                      onChange={(e) => {
                        setTName(e.target.value);
                      }}
                      onBlur={checktamil}
                      //                 onFocus={handleFocus}
                      // onBlur={handleBlur}
                    />

                   

                    {logged && tname == "" ? (
                      <span className="text-danger">Please Enter Name</span>
                    ) : (
                      ""
                    )}
                    {tname && valtamil != "" ? (
                      <span className="text-danger">{valtamil}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}
 {/* {nfocus && ( */}
                    {/* <Keyboard
                          onChange={handlename}
                          inputName="tamilKeyboardInput"
                          layout={{
                            default: [
                              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                              "{tab} அ ஆ இ ஈ உ ஊ எ ஏ ஐ ஒ ஓ ஔ",
                              "{lock} க ச த ப ற ய ர ல வ ழ ள ஃ",
                              "{shift} ் ா ி ீ ு ூ ெ ே ை ொ ோ ௌ {shift}",
                              ".com @ {space}",
                            ],
                          }}
                        /> */}
                    {/* )}  */}
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Address/முகவரி <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      onChange={(e) => {
                        setArea(e.target.value);
                      }}
                      value={area}
                    />
                    {logged && area == "" ? (
                      <span className="text-danger">Please Enter Address</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {/* <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        முகவரி <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="முகவரி"
                      onChange={(e) => {
                        setTArea(e.target.value);
                      }}
                      value={tarea}
                    />
                  
                    {logged && tarea == "" ? (
                      <span className="text-danger">Please Enter Address</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}

                  {/* <Keyboard
                          onChange={handlearea}
                          inputName="tamilKeyboardInput"
                          layout={{
                            default: [
                                "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                                "{tab} அ ஆ இ ஈ உ ஊ எ ஏ ஐ ஒ ஓ ஔ",
                                "{lock} க ச த ப ற ய ர ல வ ழ ள ஃ",
                                "{shift} ் ா ி ீ ு ூ ெ ே ை ொ ோ ௌ {shift}",
                                ".com @ {space}",
                              ],
                          }}
                        /> */}
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Mobile Number/தொலைபேசி எண்{" "}
                        <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number/தொலைபேசி எண்"
                      maxLength={10}
                      onChange={handleChange}
                      onBlur={checkmob}
                      value={phone}
                    />
                    {logged && phone == "" ? (
                      <span className="text-danger">
                        Please Enter Mobile Number
                      </span>
                    ) : (
                      ""
                    )}
                    {valmob != "" && phone && (
                      <span className="text-danger">{valmob}</span>
                    )}
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Photo
                        <span className="text-danger">
                          {" "}
                          (Accepted File - jpg, .jpeg, .png Max Upload Size 5MB)
                        </span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={photofile}
                    />
                  </div>
                  {photoerror != "" && (
                    <span className="text-danger">{photoerror}</span>
                  )}
                </Col>
              </Row>
              {errormsg != "" && (
                <span className="text-danger">{errormsg}</span>
              )}
              <hr />
              <div align="right">
                <a className="btn btn-success me-2" onClick={submitcust}>
                  Submit
                </a>

                <a
                  className="btn btn-secondary me-2"
                  onClick={() => navigate("/superadmin/customerlist")}
                >
                  Cancel
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
</>
      }
      
    </div>
  );
};
