import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Swal from "sweetalert2";
import {
  deleteamounttype,
  deletebroilertype,
  listamounttype,
  listbroilertype,
} from "../../../../services/delivery";
import {
  listbuyer,
  updatebuyerdel,
  viewbuyerdel,
} from "../../../../services/buyerdelivery";
import imageUrl from "../../../../ImageUrl";
import moment from "moment";

export const BuyerViewDelivery = () => {
  const { id } = useParams();
  const [ddate, setDDate] = useState("");
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");

  const [type, setType] = useState([]);
  const [dtype, setDType] = useState([]);

  const [atype, setAType] = useState([]);
  const [datype, setDAType] = useState([]);

  const [ccount, setCCount] = useState(0);
  const [crate, setCRate] = useState("");
  const [ckgs, setCKgs] = useState("");
  const [amt, setAmount] = useState("");
  const [ramt, setRAmount] = useState("");
  const [pamt, setPAmount] = useState("");
  const [desc, setDesc] = useState("");

  const [clist, setCustomer] = useState([]);
  const [tlist, setTypeList] = useState([]);
  const [alist, setAmtList] = useState([]);
  const [errormsg, setErrormsg] = useState("");
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    listbuyer().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label:
                response.data[i].name 
                // + " - " + response.data[i].tamil_name,
            });
          }
        }
        setCustomer(arr);
      }
    });

    listbroilertype().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].broiler_type,
            });
          }
        }
        setTypeList(arr);
      }
    });

    listamounttype().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].amount_type,
            });
          }
        }
        setAmtList(arr);
      }
    });

    listall();
  }, []);

  function listall() {
    setName({});
    setType([]);
    setAType([]);
    setDAType([]);
    setDType([]);
    viewbuyerdel(id).then((response) => {
      if (response.status == true) {
        let arr = [];
        arr.push({
          value: response.data.details.buyer,
          label:
            response.data.details.name 
            // +
            // " - " +
            // response.data.details.tamil_name,
        });

        const result = arr.reduce((obj, cur) => ({ cur }), {});
        setName(result.cur);
        let arr1 = [];
        if (response.data.broiler.length != 0) {
          for (let i = 0; i < response.data.broiler.length; i++) {
            arr1.push({
              value: response.data.broiler[i].b_type,
              label: response.data.broiler[i].broiler_type,
            });
          }
          setType(arr1);
          setDType(response.data.broiler);
        }

        let arr2 = [];
        if (response.data.amount.length != 0) {
          for (let i = 0; i < response.data.amount.length; i++) {
            arr2.push({
              value: response.data.amount[i].amt_type,
              label: response.data.amount[i].amount_type,
            });
          }
          setAType(arr2);
          setDAType(response.data.amount);
        }

        if (response.data.details.buyer_chicken_count != null) {
          setCCount(response.data.details.buyer_chicken_count);
        }
        if (response.data.details.buyer_chicken_rate != null) {
          setCRate(response.data.details.buyer_chicken_rate);
        }
        setDDate(response.data.details.buyer_del_date);
        setCKgs(response.data.details.buyer_chicken_kgs);
        setAmount(response.data.details.buyer_amount);
        setRAmount(response.data.details.buyer_rec_amount);
        setPAmount(response.data.details.buyer_pend_amount);
        if (response.data.details.buyer_description != null) {
          setDesc(response.data.details.buyer_description);
        }
        if (
          response.data.details.photo != null &&
          response.data.details.photo != ""
        ) {
          setPhoto(imageUrl + response.data.path + response.data.details.photo);
        }
      }
    });
  }
  function handleCust(e) {
    if (e == null) {
      setName("");
    } else {
      setName(e);
    }
  }

  function handleType(e) {
    if (e == null) {
      setType([]);
    } else {
      setType(e);
    }
  }

  function handleAType(e) {
    if (e == null) {
      setAType([]);
    } else {
      setAType(e);
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCCount(e.target.value);
    }
  };

  const handleCRate = (e) => {
    const regex = /^\d*\.?\d*$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCRate(e.target.value);
      setAmount(e.target.value * ckgs);
      setPAmount(e.target.value * ckgs - ramt);
    } else {
      if (e.key == ".") {
        setCRate(e.target.value);
        setAmount(e.target.value * ckgs);
        setPAmount(e.target.value * ckgs - ramt);
      }
    }
  };

  const handleKg = (e) => {
    // const regex = /^[0-9\b]+$/;
    const regex = /^\d*\.?\d*$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCKgs(e.target.value);
      setAmount(e.target.value * crate);
      setPAmount(e.target.value * crate - ramt);
    } else {
      if (e.key == ".") {
        setCKgs(e.target.value);
        setAmount(e.target.value * crate);
        setPAmount(e.target.value * crate - ramt);
      }
    }
  };

  const handleAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setAmount(e.target.value);
      setPAmount(e.target.value - ramt);
    }
  };

  const handleRAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setRAmount(e.target.value);
      setPAmount(amt - e.target.value);
    }
  };

  const handlePAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setPAmount(e.target.value);
    }
  };

  function submitdel(e) {
    e.preventDefault();
    setLogged(true);
    setErrormsg("");
    if (
      name != "" &&
      type.length != 0 &&
      crate != "" &&
      ckgs != "" &&
      amt != "" &&
      ramt != "" &&
      pamt != ""
    ) {
      var broi = "";
      if (type.length != 0) {
        for (let i = 0; i < type.length; i++) {
          if (broi == "") {
            broi = type[i].value;
          } else {
            broi = broi + "," + type[i].value;
          }
        }
      }

      var atpe = "";
      if (atype.length != 0) {
        for (let i = 0; i < atype.length; i++) {
          if (atpe == "") {
            atpe = atype[i].value;
          } else {
            atpe = atpe + "," + atype[i].value;
          }
        }
      }

      setLogged(false);
      setLoading(true);
      const temp = new FormData();
      temp.append("cust_id", name.value);
      temp.append("count", ccount);
      temp.append("btype", broi);
      temp.append("rate", crate);
      temp.append("kgs", ckgs);
      temp.append("deldate", ddate);
      temp.append("amt", amt);
      temp.append("received", ramt);
      temp.append("pending", pamt);
      temp.append("desc", desc);
      temp.append("type", 0);
      temp.append("user_id", localStorage.getItem("UID"));
      temp.append("amttype", atpe);
      temp.append("id", id);
      updatebuyerdel(temp).then((response) => {
        if (response.status == true) {
          setLoading(false);
          toast.success(response.message);
          navigate("/superadmin/companybilllist");
        }
      });
    }
  }

  function deletetype(did) {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#19b159",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        deletebroilertype(did).then((response) => {
          if (response.status == true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  function deleteamount(did) {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#19b159",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteamounttype(did).then((response) => {
          if (response.status == true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }

  const [show, setShow] = useState(false);
  const [msgcnt, setMsgcnt] = useState("");
  function handleshow() {
    setShow(true);
    setMsgcnt(
      "வணக்கம் " +
        name.label +
        "\n           உங்களின் இன்றைய டெலிவரி மொத்தம் " +
        ckgs +
        " கிலோ கோழிக்கறி, மொத்தத் தொகை: ரூ. " +
        amt +
        ". கொடுத்த தொகை: ரூ. " +
        ramt +
        ". நிலுவையில் உள்ள தொகை: " +
        pamt +
        ". \n\nஸ்ரீ வெங்கடேஷ்வர் பிராய்லர்ஸ்." +
        "\nமேலும் விவரங்களுக்கு: " +
        "https://rojabroilers.in"
    );
  }

  function handleclose() {
    setShow(false);
  }

  function backlist() {
    if (localStorage.getItem("Role") == "1") {
      navigate("/superadmin/companybilllist");
    } else if (localStorage.getItem("Role") == "2") {
      navigate("/manager/companybilllist");
    }
  }
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <div className="page-header">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}
        {!loading && (
          <>
            <h2 className="main-content-title tx-20">
              Company Bill Detail / நிறுவனத்தின் பில் விவரம்
            </h2>

            <Row className="row-sm mx-0 mt-3">
              <Col xxl={12} lg={12} md={12}>
                <Card className="custom-card overflow-hidden">
                  <Card.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Company/நிறுவனம்{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select வாங்குபவர்"
                            value={name}
                            options={clist}
                            onChange={handleCust}
                            isSearchable={true}
                            isClearable
                            hidden
                          />

                          {photo != "" && (
                            <div className="mt-2">
                              <img src={photo} width="100px" height="100px" />
                            </div>
                          )}

                          {/* <select
                        className="form-control"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Customer ---
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.id} key={i}>
                              {data.name} - {data.tamil_name}
                            </option>
                          );
                        })}
                      </select> */}
                          {logged && name == "" ? (
                            <span className="text-danger">
                              Please Select Company
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Date/தேதி <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            value={ddate}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(e) => setDDate(e.target.value)}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                            readOnly
                          />

                          {logged && ddate == "" ? (
                            <span className="text-danger">
                              Please Select Date
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      {/* <Col lg={6} md={6}>
                    <div className="form-group">
                      <label className="mb-2">
                        <b>Count/எண்ணிக்கை</b>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Count"
                        onChange={handleChange}
                        value={ccount}
                      />
                      {logged && ccount == "" ? (
                        <span className="text-danger">Please Enter Count</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col> */}

                     

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Kilo/கிலோ <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Kilo"
                            onChange={handleKg}
                            value={ckgs}
                          />
                          {logged && ckgs == "" ? (
                            <span className="text-danger">
                              Please Enter Kilo
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>Rate/விலை</b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rate"
                            onChange={handleCRate}
                            value={crate}
                          />
                          {logged && crate == "" ? (
                            <span className="text-danger">
                              Please Enter Rate
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Total Amount/மொத்த தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Amount"
                            onChange={handleAmt}
                            value={amt}
                            readOnly
                          />
                          {logged && amt == "" ? (
                            <span className="text-danger">
                              Please Enter Total Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Paid Amount/கொடுத்த தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Paid Amount"
                            onChange={handleRAmt}
                            value={ramt}
                          />
                          {logged && ramt == "" ? (
                            <span className="text-danger">
                              Please Enter Paid Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Pending Amount/நிலுவை தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pending Amount"
                            onChange={handlePAmt}
                            value={pamt}
                            readOnly
                          />
                          {logged && pamt == "" ? (
                            <span className="text-danger">
                              Please Enter Pending Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Type/வகை <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select Type"
                            value={type}
                            options={tlist}
                            onChange={handleType}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                          <div className="brtype row mt-4">
                            {dtype.map((data, i) => {
                              return (
                                <div
                                  className="col-md-4 align-self-center"
                                  key={i}
                                >
                                  <div className="form-group">
                                    <h3 className="text-gray mb-0">
                                      {data.broiler_type}
                                    </h3>
                                    <a
                                      className="deleterefer"
                                      onClick={() => deletetype(data.id)}
                                    >
                                      <i className="fa fa-close"></i>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {logged && type == "" ? (
                            <span className="text-danger">
                              Please Select Type
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Amount Paid Type/செலுத்தப்பட்ட தொகை வகை
                              {/* <span className="text-danger"> *</span> */}
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select Amount Type"
                            value={atype}
                            options={alist}
                            onChange={handleAType}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                          <div className="brtype row mt-4">
                            {datype.map((data, i) => {
                              return (
                                <div
                                  className="col-md-4 align-self-center"
                                  key={i}
                                >
                                  <div className="form-group">
                                    <h3 className="text-gray mb-0">
                                      {data.amount_type}
                                    </h3>
                                    <a
                                      className="deleterefer"
                                      onClick={() => deleteamount(data.id)}
                                    >
                                      <i className="fa fa-close"></i>
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {/* {logged && atype == "" ? (
                        <span className="text-danger">Please Select Amount Type</span>
                      ) : (
                        ""
                      )} */}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>Description/விளக்கம்</b>
                          </label>
                        </div>
                        <div className="form-group">
                          <textarea
                            rows={3}
                            className="form-control"
                            placeholder="Description"
                            onChange={(e) => {
                              setDesc(e.target.value);
                            }}
                            value={desc}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    {errormsg != "" && (
                      <span className="text-danger">{errormsg}</span>
                    )}
                    <hr />
                    <div align="right">
                      <a
                        className="btn btn-info me-2"
                        onClick={() => handleshow()}
                      >
                        Share
                      </a>
                      {localStorage.getItem("Role") == "1" && (
                        <a className="btn btn-success me-2" onClick={submitdel}>
                          Update
                        </a>
                      )}

                      <a
                        className="btn btn-secondary"
                        onClick={() => backlist()}
                      >
                        Cancel
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Modal show={show} size="lg">
              <Modal.Header>
                <h4>Share</h4>
              </Modal.Header>
              <Modal.Body>
                <Row className="row-sm mt-3">
                  <div className="text-center">
                    <WhatsappShareButton
                      url={msgcnt}
                      // title="Delivery List"
                      className="me-3"
                    >
                      <WhatsappIcon size={44} round={true} />
                    </WhatsappShareButton>
                    <FacebookShareButton
                      url={msgcnt}
                      // title="Delivery List"
                      className="me-3"
                    >
                      <FacebookIcon size={44}></FacebookIcon>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={msgcnt}
                      // title="Delivery List"
                      className="me-3"
                    >
                      <TwitterIcon size={44}></TwitterIcon>
                    </TwitterShareButton>
                  </div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <a className="btn btn-danger" onClick={handleclose}>
                  Close
                </a>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </Fragment>
  );
};
