import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function createbuyerdel(data) {
  try {
    return await axios
      .post(`${baseUrl}/createbuyerdelivery`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function updatebuyerdel(data) {
    try {
      return await axios
        .post(`${baseUrl}/updatebuyerdelivery`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function listbuyer() {
    try {
      return await axios
        .get(`${baseUrl}/buyeractivelist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  
export async function listbuyerdel() {
    try {
      return await axios
        .get(`${baseUrl}/buyerdeliverylist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function viewbuyerdel(id) {
    try {
      return await axios
        .get(`${baseUrl}/viewbuyerdelivery/`+id)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


export async function filterbuyerdelivery(cid,did,tid,type) {
  try {
    return await axios
      .get(`${baseUrl}/buyerdelivery-pdf-view/`+cid+`/`+did+`/`+tid + `/`+type)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function viewbuyerdelpending(id) {
  try {
    return await axios
      .get(`${baseUrl}/viewbuyerpendingdetail/`+id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function deletebuyerdelpending(data) {
  try {
    return await axios
      .post(`${baseUrl}/deletebuyerpending`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}
function catcherror(error) {
  console.log(error.response)
  if (error.response.status == 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status == 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status == 0) {
    toast.error(error.message);
  } else if (error.response.status == 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status != 200 &&
    error.response.data.status == false &&
    error.response.data.code != 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
