import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export async function logindata(data) {
  try {
    return await axios
      .post(`${baseUrl}/login`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function profileview(id) {
  try {
   
    return await axios
      .get(`${baseUrl}/userview/`+id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function countdetails() {
  try {
   
    return await axios
      .get(`${baseUrl}/dashboardcount`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}


export async function daywisedetails(id,type) {
  try {
   
    return await axios
      .get(`${baseUrl}/datewisereport/${id}/${type}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

function catcherror(error) {
  console.log(error.response)
  if (error.response.status == 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status == 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status == 0) {
    toast.error(error.message);
  } else if (error.response.status == 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status != 200 &&
    error.response.data.status == false &&
    error.response.data.code != 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
