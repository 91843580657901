import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import imageUrl from "../../../../ImageUrl";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { listcustactive } from "../../../../services/customer";
import {
    createdel,
  deletedelpending,
  listamounttype,
  listdel,
  viewdelpending,
} from "../../../../services/delivery";
export const DeliveryListPending = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const columnDefs = [
    {
      headerName: "Action/செயல்",
      //headerTooltip "Action/செயல்",
      width: 100,
      field: "id",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "center" },

      cellRenderer: function (params) {
        return (
          <a
            className="btn-sm btn-danger"
            onClick={() => dellist(params.value)}
          >
            <i className="fa fa-trash fa-lg"></i>
          </a>
        );
      },
    },
    {
      headerName: "Photo",
      //headerTooltip "Photo",
      width: 100,
      field: "photo",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if (params.value != null) {
          return (
            <img
              src={imageUrl + path + params.value}
              width={"50px"}
              height={"50px"}
              onError={(e) => errorhandle(e)}
            />
          );
        }
      },
    },
    {
      headerName: "Company/நிறுவனம்",
      //headerTooltip "Company",
      width: 200,
      field: "name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Date/தேதி",
      //headerTooltip "Date/தேதி",
      width: 130,
      field: "del_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },

    {
      headerName: "Amount Type/தொகை வகை",
      //headerTooltip "Amount Type/தொகை வகை",
      width: 130,
      field: "amount_types",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount/தொகை",
      //headerTooltip "Amount/தொகை",
      width: 130,
      field: "amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Paid/கொடுத்தது",
      //headerTooltip "Paid/கொடுத்தது",
      width: 130,
      field: "rec_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pending/நிலுவை",
      //headerTooltip "Pending/நிலுவை",
      width: 130,
      field: "pend_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/img/user.png");
    ev.target.onerror = null;
  }

  const [filterProduct, setFilterProduct] = useState([]);
  useEffect(() => {
    listall();
    listcustactive().then((response) => {
      if (response.data.length != 0) {
        let arr = [];
        for (let i = 0; i < response.data.length; i++) {
          arr.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCustomer(arr);
      }
    });
    listamounttype().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].amount_type,
            });
          }
        }
        setAmtList(arr);
      }
    });
  }, []);
  const [path, setPath] = useState("");
  function listall() {
    setFilterProduct([]);
    listdel().then((res) => {
      if (res.status == true) {
        const sdata = res.data.details.filter((item) => {
          return item.pending_type == 1;
        });
        if (sdata != undefined) {
          setFilterProduct(sdata);
        }
        setPath(res.data.path);
      }
    });
  }
  const [clist, setCustomer] = useState([]);
  const [name, setName] = useState("");
  const [amt, setAmount] = useState(0);
  const [ramt, setRAmount] = useState(0);
  const [pamt, setPAmount] = useState(0);
  const [paid, setPaid] = useState("");
  const [overall, setOverall] = useState(0);

  const [atype, setAType] = useState([]);
  const [ddate, setDDate] = useState("");

  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [alist, setAmtList] = useState([]);
  function handleshow() {
    setName("");
    setDDate("")
    setErrorMsg("");
    setAmount(0);
    setRAmount(0);
    setPAmount(0);
    setPaid("");
    setOverall(0);
    setShow(true);
  }
  function handleclose() {
    setShow(false);
    setDDate("")
    setName("");
    setErrorMsg("");
    setAmount(0);
    setRAmount(0);
    setPAmount(0);
    setPaid("");
    setOverall(0);
  }

  function handlecust(e) {
    setAmount(0);
    setRAmount(0);
    setPAmount(0);
    setPaid("");
    setOverall(0);
    if (e == null) {
      setName("");
    } else {
      setName(e);
      viewdelpending(e.value).then((res) => {
        if (res.status == true) {
          if (res.data.details.amount != null) {
            setAmount(res.data.details.amount);
          }
          if (res.data.details.received != null) {
            if (res.data.pending.received != null) {
              setRAmount(res.data.details.received + res.data.pending.received);
            } else {
              setRAmount(res.data.details.received);
            }
          }
          if (res.data.details.pending != null) {
            if (res.data.pending.received != null) {
              setPAmount(res.data.details.pending - res.data.pending.received);
            } else {
              setPAmount(res.data.details.pending);
            }
          }
        }
      });
    }
  }

  const handleRAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setPaid(e.target.value);
      setOverall(pamt - e.target.value);
    }
  };

  function handleAType(e) {
    if (e == null) {
      setAType([]);
    } else {
      setAType(e);
    }
  }

  function submitdel(e) {
    e.preventDefault();
    setErrorMsg("");
    if (ddate != "") {
      if (name != "") {
        if (paid != "" && paid != 0) {
          if(parseInt(paid) > parseInt(pamt)){
            setErrorMsg("Received Amount should be less than or equal to Pending Amount");
          }else{
            var atpe = "";
          if (atype.length != 0) {
            for (let i = 0; i < atype.length; i++) {
              if (atpe == "") {
                atpe = atype[i].value;
              } else {
                atpe = atpe + "," + atype[i].value;
              }
            }
          }
          const temp = new FormData();
          temp.append("cust_id", name.value);
          temp.append("count", 0);
          temp.append("rate", 0);
          temp.append("btype", "");
          temp.append("kgs", "");
          temp.append("deldate", ddate);
          temp.append("amt", 0);
          temp.append("received", paid);
          temp.append("pending", 0);
          temp.append("amttype", atpe);
          temp.append("type", 1);
          temp.append("desc", "");
          temp.append("user_id", localStorage.getItem("UID"));

          createdel(temp).then((response) => {
            if (response.status == true) {
              toast.success(response.message);
              handleclose();
              listall();
            }
          });
          }
        } else {
          setErrorMsg("Please Enter Received Amount");
        }
      } else {
        setErrorMsg("Please Select Customer Name");
      }
    } else {
      setErrorMsg("Please Select Date");
    }
  }

  function dellist(did) {
    Swal.fire({
      text: "Are you sure want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ac0404",
      cancelButtonColor: "#6259ca",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const temp = new FormData();
        temp.append("id", did);

        deletedelpending(temp).then((response) => {
          if (response.status == true) {
            listall();
            toast.success(response.message);
          }
        });
      }
    });
  }
  return (
    <div className="page-header">
      <h2 className="main-content-title tx-20">
        Pending Customer Bill List / நிலுவையில் உள்ள வாடிக்கையாளர் பில் பட்டியல்
      </h2>
      <Row className="row-sm mx-0 mt-3">
        <div align="right">
          <a className="btn btn-success" onClick={handleshow}>
            {" "}
            Add Pending
          </a>
        </div>
        <Col xxl={12} lg={12} md={12}>
          <div className="ag-theme-alpine ag-style mt-2">
            <AgGridReact
              columnDefs={columnDefs}
              rowData={filterProduct}
              pagination="true"
              paginationPageSize="10"
              suppressExcelExport={true}
              suppressDragLeaveHidesColumns={true}
            />
          </div>
        </Col>

        <Modal show={show} size="lg">
          <Modal.Header>
            <h5> Add Pending Amount</h5>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-sm mx-0">
              <Col lg={6} md={6}>
                <div className="form-group">
                  <label className="mb-2">
                    <b>
                      Date/தேதி <span className="text-danger"> *</span>
                    </b>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control"
                    value={ddate}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => setDDate(e.target.value)}
                    onKeyPress={(event) => {
                      event.preventDefault();
                    }}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                  />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="form-group">
                  <label className="mb-2">
                    <b>
                      Customer/வாடிக்கையாளர் <span className="text-danger"> *</span>
                    </b>
                  </label>
                </div>
                <div className="form-group">
                  <Select
                    className="dropdown"
                    placeholder="Select Customer"
                    value={name}
                    options={clist}
                    onChange={handlecust}
                    isSearchable={true}
                    isClearable
                    hidden
                  />
                </div>
              </Col>
              <div className="row mt-4 mb-5">
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="mb-2">Amount/மொத்த தொகை</label>
                    <h5 style={{ color: "#19b159" }}>Rs. {amt}</h5>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="mb-2">Received/பெற்ற தொகை</label>
                    <h5 style={{ color: "#0e8cb3" }}>Rs. {ramt}</h5>
                  </div>
                </Col>
                <Col lg={4} md={4}>
                  <div className="form-group">
                    <label className="mb-2">Pending/நிலுவை தொகை</label>
                    <h5 className="text-danger">Rs. {pamt}</h5>
                  </div>
                </Col>
              </div>
              <Col lg={6} md={6}>
                <div className="form-group">
                  <label className="mb-2">
                    <b>
                      Received Amount/பெறப்பட்ட தொகை{" "}
                      <span className="text-danger"> *</span>
                    </b>
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Received Amount"
                    onChange={handleRAmt}
                    value={paid}
                  />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="form-group">
                  <label className="mb-2">
                    <b>Paid Type/செலுத்தப்பட்ட தொகை வகை</b>
                  </label>
                </div>
                <div className="form-group">
                  <Select
                    className="dropdown"
                    placeholder="Select Amount Type"
                    value={atype}
                    options={alist}
                    onChange={handleAType}
                    isSearchable={true}
                    isMulti
                    isClearable
                    hidden
                  />
                </div>
              </Col>

              <Col lg={12} md={12}>
                <div className="form-group">
                  <label className="mt-4">
                    Overall Pending amount/மொத்த நிலுவையில் உள்ள தொகை
                  </label>
                  <h5 style={{ color: "#0e8cb3" }}>Rs. {overall}</h5>
                </div>
              </Col>
              {errorMsg != "" && (
                <span className="text-danger">{errorMsg}</span>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
           

            <a className="btn btn-success" onClick={submitdel}>
              Submit
            </a>
            <a className="btn btn-danger" onClick={handleclose}>
              Close
            </a>
          </Modal.Footer>
        </Modal>
      </Row>
    </div>
  );
};
