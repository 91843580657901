import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { listcustactive, viewcust } from "../../../../services/customer";
import {
  createdel,
  listamounttype,
  listbroilertype,
} from "../../../../services/delivery";
import { toast } from "react-toastify";
import Select from "react-select";
import imageUrl from "../../../../ImageUrl";
import moment from "moment";

export const AddDelivery = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [ddate, setDDate] = useState("");
  const [type, setType] = useState([]);
  const [atype, setAType] = useState([]);
  const [ccount, setCCount] = useState(0);
  const [crate, setCRate] = useState("");
  const [ckgs, setCKgs] = useState("");
  const [amt, setAmount] = useState("");
  const [ramt, setRAmount] = useState("");
  const [pamt, setPAmount] = useState("");
  const [desc, setDesc] = useState("");

  const [clist, setCustomer] = useState([]);
  const [tlist, setTypeList] = useState([]);
  const [alist, setAmtList] = useState([]);
  const [photo, setPhoto] = useState("");

  const [errormsg, setErrormsg] = useState("");
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    listcustactive().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].name,
              //  + " - " + response.data[i].tamil_name,
            });
          }
        }
        setCustomer(arr);
      }
    });

    listbroilertype().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].broiler_type,
            });
          }
        }
        setTypeList(arr);
      }
    });

    listamounttype().then((response) => {
      if (response.status == true) {
        let arr = [];
        if (response.data.length != 0) {
          for (let i = 0; i < response.data.length; i++) {
            arr.push({
              value: response.data[i].id,
              label: response.data[i].amount_type,
            });
          }
        }
        setAmtList(arr);
      }
    });
  }, []);

  function handleCust(e) {
    setPhoto("");
    if (e == null) {
      setName("");
    } else {
      setName(e);
      viewcust(e.value, 2).then((res) => {
        if (res.status == true) {
          if (res.data.details.photo != null) {
            setPhoto(imageUrl + res.data.path + res.data.details.photo);
          }
        }
      });
    }
  }

  function handleType(e) {
    if (e == null) {
      setType([]);
    } else {
      setType(e);
    }
  }

  function handleAType(e) {
    if (e == null) {
      setAType([]);
    } else {
      setAType(e);
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCCount(e.target.value);
    }
  };

  const handleCRate = (e) => {
    const regex = /^\d*\.?\d*$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCRate(e.target.value);
      setAmount(e.target.value * ckgs);
      setPAmount(e.target.value * ckgs - ramt);
    } else {
      if (e.key == ".") {
        setCRate(e.target.value);
        setAmount(e.target.value * ckgs);
        setPAmount(e.target.value * ckgs - ramt);
      }
    }
  };

  const handleKg = (e) => {
    // const regex = /^[0-9\b]+$/;
    const regex = /^\d*\.?\d*$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setCKgs(e.target.value);
      setAmount(e.target.value * crate);
      setPAmount(e.target.value * crate - ramt);
    } else {
      if (e.key == ".") {
        setCKgs(e.target.value);
        setAmount(e.target.value * crate);
        setPAmount(e.target.value * crate - ramt);
      }
    }
  };

  const handleAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setAmount(e.target.value);
      setPAmount(e.target.value - ramt);
    }
  };

  const handleRAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setRAmount(e.target.value);
      setPAmount(amt - e.target.value);
    }
  };

  const handlePAmt = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setPAmount(e.target.value);
    }
  };

  function submitdel(e) {
    e.preventDefault();
    setLogged(true);
    setErrormsg("");
    if (
      name != "" &&
      // type.length != 0 &&
      ckgs != "" &&
      amt != "" &&
      ramt != "" &&
      pamt != ""
    ) {
      if (ramt > amt) {
        setErrormsg(
          "Received Amount should be less than or equal to Total Amount"
        );
      } else {
        var broi = "";
        if (type.length != 0) {
          for (let i = 0; i < type.length; i++) {
            if (broi == "") {
              broi = type[i].value;
            } else {
              broi = broi + "," + type[i].value;
            }
          }
        }

        var atpe = "";
        if (atype.length != 0) {
          for (let i = 0; i < atype.length; i++) {
            if (atpe == "") {
              atpe = atype[i].value;
            } else {
              atpe = atpe + "," + atype[i].value;
            }
          }
        }

        setLogged(false);
        setLoading(true);
        const temp = new FormData();
        temp.append("cust_id", name.value);
        temp.append("count", ccount);
        temp.append("rate", crate);
        temp.append("btype", broi);
        temp.append("kgs", ckgs);
        temp.append("deldate", ddate);
        temp.append("amt", amt);
        temp.append("received", ramt);
        temp.append("pending", pamt);
        temp.append("amttype", atpe);
        temp.append("type", 0);
        temp.append("desc", desc);
        temp.append("user_id", localStorage.getItem("UID"));

        createdel(temp).then((response) => {
          if (response.status == true) {
            setLoading(false);
            toast.success(response.message);
            navigate("/superadmin/deliverylist");
          }
        });
      }
    }
  }

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <div className="page-header">
        {loading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
            <h3>Loading...Please do not go back or close the window.</h3>
          </div>
        )}

        {!loading && (
          <>
            <h2 className="main-content-title tx-20">
              Add Delivery / டெலிவரி சேர்த்தல்
            </h2>

            <Row className="row-sm mx-0 mt-3">
              <Col xxl={12} lg={12} md={12}>
                <Card className="custom-card overflow-hidden">
                  <Card.Body>
                    <Row className="row-sm mx-0">
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Customer/வாடிக்கையாளர்{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select Customer"
                            value={name}
                            options={clist}
                            onChange={handleCust}
                            isSearchable={true}
                            isClearable
                            hidden
                          />
                          {photo != "" && (
                            <div className="mt-2">
                              <img src={photo} width="100px" height="100px" />
                            </div>
                          )}
                          {/* <select
                        className="form-control"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      >
                        <option value="" defaultValue disabled>
                          --- Select Customer ---
                        </option>
                        {clist.map((data, i) => {
                          return (
                            <option value={data.id} key={i}>
                              {data.name} - {data.tamil_name}
                            </option>
                          );
                        })}
                      </select> */}
                          {logged && name == "" ? (
                            <span className="text-danger">
                              Please Select Customer
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Date/தேதி <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            value={ddate}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            onChange={(e) => setDDate(e.target.value)}
                            onKeyPress={(event) => {
                              event.preventDefault();
                            }}
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                          />

                          {logged && ddate == "" ? (
                            <span className="text-danger">
                              Please Select Date
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Type/வகை <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select Type"
                            value={type}
                            options={tlist}
                            onChange={handleType}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                          {logged && type == "" ? (
                            <span className="text-danger">
                              Please Select Type
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      {/* <Col lg={6} md={6}>
                    <div className="form-group">
                      <label className="mb-2">
                        <b>Count/எண்ணிக்கை</b>
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Count"
                        onChange={handleChange}
                        value={ccount}
                      />
                       {logged && ccount == "" ? (
                        <span className="text-danger">Please Enter Count</span>
                      ) : (
                        ""
                      )} 
                    </div>
                  </Col> */}

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Kilo/கிலோ <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Kilo"
                            onChange={handleKg}
                            value={ckgs}
                          />
                          {logged && ckgs == "" ? (
                            <span className="text-danger">
                              Please Enter Kilo
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>Rate/விலை</b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Rate"
                            onChange={handleCRate}
                            value={crate}
                          />
                          {logged && crate == "" ? (
                            <span className="text-danger">
                              Please Enter Rate
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Total Amount/மொத்த தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Total Amount"
                            onChange={handleAmt}
                            value={amt}
                            readOnly
                          />
                          {logged && amt == "" ? (
                            <span className="text-danger">
                              Please Enter Total Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Received Amount/பெறப்பட்ட தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Received Amount"
                            onChange={handleRAmt}
                            value={ramt}
                          />
                          {logged && ramt == "" ? (
                            <span className="text-danger">
                              Please Enter Received Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Pending Amount/நிலுவையில் உள்ள தொகை{" "}
                              <span className="text-danger"> *</span>
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Pending Amount"
                            onChange={handlePAmt}
                            value={pamt}
                            readOnly
                          />
                          {logged && pamt == "" ? (
                            <span className="text-danger">
                              Please Enter Pending Amount
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>
                              Amount Received Type/பெறப்பட்ட தொகை வகை
                              {/* <span className="text-danger"> *</span> */}
                            </b>
                          </label>
                        </div>
                        <div className="form-group">
                          <Select
                            className="dropdown"
                            placeholder="Select Amount Type"
                            value={atype}
                            options={alist}
                            onChange={handleAType}
                            isSearchable={true}
                            isMulti
                            isClearable
                            hidden
                          />
                          {/* {logged && atype == "" ? (
                        <span className="text-danger">Please Select Type</span>
                      ) : (
                        ""
                      )} */}
                        </div>
                      </Col>

                      <Col lg={6} md={6}>
                        <div className="form-group">
                          <label className="mb-2">
                            <b>Description/விளக்கம்</b>
                          </label>
                        </div>
                        <div className="form-group">
                          <textarea
                            rows={3}
                            className="form-control"
                            placeholder="Description"
                            onChange={(e) => {
                              setDesc(e.target.value);
                            }}
                            value={desc}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    {errormsg != "" && (
                      <span className="text-danger">{errormsg}</span>
                    )}
                    <hr />
                    <div align="right">
                      <a className="btn btn-success me-2" onClick={submitdel}>
                        Submit
                      </a>

                      <a
                        className="btn btn-secondary me-2"
                        onClick={() => navigate("/superadmin/deliverylist")}
                      >
                        Cancel
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Fragment>
  );
};
