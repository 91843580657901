import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "react-simple-keyboard/build/css/index.css";
import { updatecust, viewcust } from "../../../../services/customer";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import imageUrl from "../../../../ImageUrl";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import baseUrl from "../../../../Api";
import { filterdelivery } from "../../../../services/delivery";
export const ViewCustomer = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [tname, setTName] = useState("");
  const [area, setArea] = useState("");
  const [tarea, setTArea] = useState("");
  const [phone, setPhone] = useState("");
  const [pfile, setPFile] = useState("");
  const [phfile, setPhotoFile] = useState("");

  const [errormsg, setErrormsg] = useState("");
  const [logged, setLogged] = useState(false);
  const [photoerror, setPhotoError] = useState("");
  const navigate = useNavigate();

  const getRowStyle = params => {
    if (params.data && params.data.pending_type == 1) { 
        return { background: '#ceeba0' }; // Change the background color as desired
    }
    return null;
};

  const columnDefs = [
    {
      headerName: "Date/தேதி",
      //headerTooltip "Date/தேதி",
      width: 120,
      field: "del_date",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Type/வகை",
      //headerTooltip "Type/வகை",
      width: 150,
      field: "broiler_types",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Rate/விலை",
      //headerTooltip "Rate/விலை",
      width: 120,
      field: "chicken_rate",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Kilo/கிலோ",
      //headerTooltip "Kilo/கிலோ",
      width: 110,
      field: "chicken_kgs",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Amount/தொகை",
      //headerTooltip "Amount/தொகை",
      width: 140,
      field: "amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Received/பெற்றது",
      //headerTooltip "Received/பெற்றது",
      width: 140,
      field: "rec_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pending/நிலுவை",
      //headerTooltip "Pending/நிலுவை",
      width: 140,
      field: "pend_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Balance/மீதம்",
      //headerTooltip "Pending/நிலுவை",
      width: 130,
      field: "balance",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  function photofile(event) {
    setPhotoError("");
    setPFile("");
    if (event.target.files.length != 0) {
      if (event.target.files[0].name.match(/\.(jpg|jpeg|png)$/i)) {
        setPFile(event.target.files[0]);
      } else {
        setPhotoError("Photo should be jpg, jpeg, or png format");
      }
      var size = event.target.files[0].size / 1024 / 1024;
      if (size > 5) {
        setPhotoError("Photo size should be less than 5MB");
      }
    }
  }

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };
  const [valmob, setValMob] = useState("");
  function checkmob(e) {
    e.preventDefault();
    setValMob("");
    var obj = {};
    if (e.target.value != "") {
      if (e.target.value.length != 10) {
        setValMob("Mobile Number must be 10 characters");
      }

      for (let x = 0, length = e.target.value.length; x < length; x++) {
        var l = e.target.value.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] == 10) {
        setValMob("Enter a Valid Mobile Number");
      }
    }
  }

  function submitcust(e) {
    e.preventDefault();
    setLogged(true);
    setValMob("");
    setErrormsg("");
    var check = "";
    if (phone != "") {
      if (phone.length != 10) {
        check = "Mobile Number must be 10 characters";
        setValMob("Mobile Number must be 10 characters");
      }
      var obj = {};
      for (let x = 0, length = phone.length; x < length; x++) {
        var l = phone.charAt(x);
        obj[l] = isNaN(obj[l]) ? 1 : obj[l] + 1;
      }

      if (obj[l] == 10) {
        check = "Enter a Valid Mobile Number";
        setValMob("Enter a Valid Mobile Number");
      }
    }

    if (
      name != "" &&
      // tname != "" &&
      area != "" &&
      // tarea != "" &&
      phone != "" &&
      check == "" &&
      photoerror == ""
    ) {
      setLogged(false);
      setLoading(true);
      const temp = new FormData();
      temp.append("name", name);
      temp.append("tamilname", tname);
      temp.append("area", area);
      temp.append("tamilarea", tarea);
      temp.append("phone", phone);
      temp.append("photo", pfile);
      temp.append("type", 2);

      temp.append("user_id", localStorage.getItem("UID"));
      temp.append("id", id);
      updatecust(temp).then((res) => {
        if (res.status == true) {
          setLoading(false);
          toast.success(res.message);
          navigate("/superadmin/customerlist");
        }
      });
    }
  }

  const [filterProduct, setFilterProduct] = useState([]);

  const [Amt, setAmt] = useState("");
  const [Ramt, setRamt] = useState("");
  const [Pamt, setPamt] = useState("");

  useEffect(() => {
    setCurDate(moment(new Date()).format("YYYY-MM-DD"));
    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
    setEndDate(moment(new Date()).format("YYYY-MM-DD"));

    viewcust(id,2).then((res) => {
      if (res.status == true) {
        setName(res.data.details.name);
        setTName(res.data.details.tamil_name);
        setArea(res.data.details.area);
        setTArea(res.data.details.tamil_area);
        setPhone(res.data.details.phone);
        if (res.data.details.photo != null) {
          setPhotoFile(imageUrl + res.data.path + res.data.details.photo);
        }
        // setFilterProduct(res.data.delivery);
        setAmt(res.data.amount);
        setRamt(res.data.received);
        setPamt(res.data.pending- res.data.pen_received);
      }
    });
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    setStartDate(moment(new Date()).format("YYYY-MM-DD"));
    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    viewcust(id,2).then((res) => {
      if (res.status == true) {
        let arr=[];
        if(res.data.delivery.length > 0){
          for(let i=0;i<res.data.delivery.length;i++){
            arr.push({
              id:res.data.delivery[i].id,
              customer:res.data.delivery[i].customer,
              chicken_count:res.data.delivery[i].chicken_count,
              chicken_rate:res.data.delivery[i].chicken_rate,
              chicken_kgs:res.data.delivery[i].chicken_kgs,
              del_date:res.data.delivery[i].del_date,
              amount:res.data.delivery[i].amount,
              rec_amount:res.data.delivery[i].rec_amount,
              pend_amount:res.data.delivery[i].pend_amount,
              description:res.data.delivery[i].description,
              pending_type:res.data.delivery[i].pending_type,
              active:res.data.delivery[i].active,
              created_by:res.data.delivery[i].created_by,
              updated_by:res.data.delivery[i].updated_by,
              created_at:res.data.delivery[i].created_at,
              updated_at:res.data.delivery[i].updated_at,
              name:res.data.delivery[i].name,
              tamil_name:res.data.delivery[i].tamil_name,
              area:res.data.delivery[i].area,
              tamil_area:res.data.delivery[i].tamil_area,
              phone:res.data.delivery[i].phone,
              broiler_types:res.data.delivery[i].broiler_types,
              balance:res.data.delivery[i].pend_amount
            })
          }
        }
        // Clone the array to avoid mutating the original array
        const updatedDelivery = arr.map((item) => ({ ...item }));

        // Function to subtract buyer_pend_amount from the previous array
        const subtractFromPrevious = (currentItem, previousItem) => {
          if(currentItem.pending_type == 0){
            currentItem.balance = String(
              parseInt(currentItem.balance) +
                parseInt(previousItem.balance)
            );
          }else if(currentItem.pending_type == 1){
            currentItem.balance = String(
              parseInt(previousItem.balance) - parseInt(currentItem.rec_amount) 
                
            );
          }
          
        };

        // Subtract buyer_pend_amount from the previous array for each item in the updatedDelivery array
        for (let i = 1; i < updatedDelivery.length; i++) {
          subtractFromPrevious(updatedDelivery[i], updatedDelivery[i - 1]);
        }

        // Set the updated array using setFilterProduct
        setFilterProduct(updatedDelivery);
      }
    });
  }

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [curdate, setCurDate] = useState("");
  function downloadpdf(pdf) {
    if (pdf == 1) {
      filterdelivery(id, startDate, endDate, 2).then((res) => {
        if (res.status == true) {
          if (res.data.delivery.length > 0) {
            window.open(
              baseUrl +
                "/delivery-pdf-view/" +
                id +
                "/" +
                startDate +
                "/" +
                endDate +
                "/" +
                pdf,
              "_blank"
            );
          } else {
            toast.error(
              "No Delivery Available for the selected start and end date"
            );
          }
        }
      });
    } else if (pdf == 2) {
      setFilterProduct([]);

      filterdelivery(id, startDate, endDate, pdf).then((res) => {
        if (res.status == true) {
          // setFilterProduct(res.data.delivery);
          let arr=[];
        if(res.data.delivery.length > 0){
          for(let i=0;i<res.data.delivery.length;i++){
            arr.push({
              id:res.data.delivery[i].id,
              customer:res.data.delivery[i].customer,
              chicken_count:res.data.delivery[i].chicken_count,
              chicken_rate:res.data.delivery[i].chicken_rate,
              chicken_kgs:res.data.delivery[i].chicken_kgs,
              del_date:res.data.delivery[i].del_date,
              amount:res.data.delivery[i].amount,
              rec_amount:res.data.delivery[i].rec_amount,
              pend_amount:res.data.delivery[i].pend_amount,
              description:res.data.delivery[i].description,
              pending_type:res.data.delivery[i].pending_type,
              active:res.data.delivery[i].active,
              created_by:res.data.delivery[i].created_by,
              updated_by:res.data.delivery[i].updated_by,
              created_at:res.data.delivery[i].created_at,
              updated_at:res.data.delivery[i].updated_at,
              name:res.data.delivery[i].name,
              tamil_name:res.data.delivery[i].tamil_name,
              area:res.data.delivery[i].area,
              tamil_area:res.data.delivery[i].tamil_area,
              phone:res.data.delivery[i].phone,
              broiler_types:res.data.delivery[i].broiler_types,
              balance:res.data.delivery[i].pend_amount
            })
          }
        }
        // Clone the array to avoid mutating the original array
        const updatedDelivery = arr.map((item) => ({ ...item }));

        // Function to subtract buyer_pend_amount from the previous array
        const subtractFromPrevious = (currentItem, previousItem) => {
          if(currentItem.pending_type == 0){
            currentItem.balance = String(
              parseInt(currentItem.balance) +
                parseInt(previousItem.balance)
            );
          }else if(currentItem.pending_type == 1){
            currentItem.balance = String(
              parseInt(previousItem.balance) - parseInt(currentItem.rec_amount) 
                
            );
          }
          
        };

        // Subtract buyer_pend_amount from the previous array for each item in the updatedDelivery array
        for (let i = 1; i < updatedDelivery.length; i++) {
          subtractFromPrevious(updatedDelivery[i], updatedDelivery[i - 1]);
        }

        // Set the updated array using setFilterProduct
        setFilterProduct(updatedDelivery);
        }
      });
    }
  }
  const [loading, setLoading] = useState(false);
  return (
    <div className="page-header">

{loading && (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <h3>Loading...Please do not go back or close the window.</h3>
        </div>
      )}

      {!loading &&
<>
<h2 className="main-content-title tx-20">
        Customer Detail / வாடிக்கையாளர் விவரம்
      </h2>

      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <Card className="custom-card overflow-hidden">
            <Card.Body>
              <Row className="row-sm mx-0">
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Customer Name <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Customer Name"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                    {logged && name == "" ? (
                      <span className="text-danger">Please Enter Name</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {/* <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        வாடிக்கையாளர் பெயர்{" "}
                        <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"வாடிக்கையாளர் பெயர்"}
                      value={tname}
                      onChange={(e) => {
                        setTName(e.target.value);
                      }}
                    />

                    {logged && tname == "" ? (
                      <span className="text-danger">Please Enter Name</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Address <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      onChange={(e) => {
                        setArea(e.target.value);
                      }}
                      value={area}
                    />
                    {logged && area == "" ? (
                      <span className="text-danger">Please Enter Address</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                {/* <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        முகவரி <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="முகவரி"
                      onChange={(e) => {
                        setTArea(e.target.value);
                      }}
                      value={tarea}
                    />
                    {logged && tarea == "" ? (
                      <span className="text-danger">Please Enter Address</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}
                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Mobile Number/தொலைபேசி எண்{" "}
                        <span className="text-danger"> *</span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile Number/தொலைபேசி எண்"
                      maxLength={10}
                      onChange={handleChange}
                      onBlur={checkmob}
                      value={phone}
                    />
                    {logged && phone == "" ? (
                      <span className="text-danger">
                        Please Enter Mobile Number
                      </span>
                    ) : (
                      ""
                    )}
                    {valmob != "" && phone && (
                      <span className="text-danger">{valmob}</span>
                    )}
                  </div>
                </Col>

                <Col lg={6} md={6}>
                  <div className="form-group">
                    <label className="mb-2">
                      <b>
                        Photo
                        <span className="text-danger">
                          {" "}
                          (Accepted File - jpg, .jpeg, .png Max Upload Size 5MB)
                        </span>
                      </b>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      accept=".jpg, .jpeg, .png"
                      onChange={photofile}
                    />
                    {phfile != "" && (
                      <img src={phfile} width="100px" height="100px" />
                    )}
                  </div>
                  {photoerror != "" && (
                    <span className="text-danger">{photoerror}</span>
                  )}
                </Col>
              </Row>
              {errormsg != "" && (
                <span className="text-danger">{errormsg}</span>
              )}
              <hr />
              <div align="right">
                <a className="btn btn-success me-2" onClick={submitcust}>
                  Update
                </a>
                <a
                  className="btn btn-secondary me-2"
                  onClick={() => navigate("/superadmin/customerlist")}
                >
                  Cancel
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr />

      <h3 className="main-content-title tx-16">
        Delivery List / டெலிவரி பட்டியல்
      </h3>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
          <div className="row mb-3">
            <div className="col-md-3">
              <label className="form-label">Start Date/தொடக்க தேதி</label>
              <input
                type="date"
                className="form-control"
                max={curdate}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                onKeyPress={(event) => {
                  event.preventDefault();
                }}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">End Date/கடைசி தேதி</label>
              <input
                type="date"
                className="form-control"
                min={startDate}
                max={curdate}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                onKeyPress={(event) => {
                  event.preventDefault();
                }}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
              />
            </div>
            <div className="col-md-6 mt-4">
              <div align="right">
                <a
                  className="btn btn-secondary me-2"
                  onClick={() => downloadpdf(1)}
                >
                  <i className="fa fa-download"></i> Download PDF
                </a>

                <a className="btn btn-info me-2" onClick={() => downloadpdf(2)}>
                  <i className="fa fa-filter"></i> Filter
                </a>
                <a className="btn btn-danger" onClick={listall}>
                  <i className="fa fa-remove"></i> Clear
                </a>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <h5 style={{ color: "#19b159" }}>Total Amount : Rs. {Amt}</h5>
            </div>
            <div className="col-md-4">
              <h5 style={{ color: "#0e8cb3" }}>Received Amount : Rs. {Ramt}</h5>
            </div>
            <div className="col-md-4">
              <h5 style={{ color: "red" }}>Pending Amount : Rs. {Pamt}</h5>
            </div>
          </div>
          <div className="ag-theme-alpine ag-style mt-2">
            <AgGridReact
            getRowStyle={getRowStyle}
              columnDefs={columnDefs}
              rowData={filterProduct}
              pagination="true"
              paginationPageSize="10"
              suppressExcelExport={true}
              suppressDragLeaveHidesColumns={true}
            />
          </div>
        </Col>
      </Row>
</>
      }
    
    </div>
  );
};
//
