import React, { useEffect, useState } from "react";
import { profileview } from "../../services/login";

export const Profile = () => {
  const [area, setArea] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    profileview(localStorage.getItem("UID")).then((response) => {
      if (response.status == true) {
        setArea(response.data.area);
        setPhone(response.data.mobile);
        setEmail(response.data.email);
      }
    });
  });
  return (
    <div className="page-header">
      <div className="box box-widget widget-user">
        <div className="widget-user-header bg-img bbsr-0 bber-0" data-overlay="5">
          <h3 className="widget-user-username text-white">
            {localStorage.getItem("Name")}
          </h3>
          {/* <h6 className="widget-user-desc text-white">Designer</h6> */}
        </div>
        <div className="widget-user-image">
          <img
            className="rounded-circle"
            src={require("../../assets/img/user.png")}
            alt="User Avatar"
          />
        </div>
        <div className="box-footer">
          <div className="row">
            <div className="col-sm-4">
              <div className="description-block">
                <span className="description-text">Area</span>

                <h5 className="description-header">{area}</h5>
              </div>
            </div>
            <div className="col-sm-4 be-1 bs-1">
              <div className="description-block">
                <span className="description-text">Phone Number</span>

                <h5 className="description-header">{phone}</h5>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="description-block">
                <span className="description-text">Email</span>

                <h5 className="description-header">{email}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
