import axios from "axios";
import { Navigate } from "react-router-dom";
import baseUrl from "../Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


export async function custvalidatephone(data) {
  try {
    return await axios
      .post(`${baseUrl}/validatesalesphone`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function custvalidatename(data) {
  try {
    return await axios
      .post(`${baseUrl}/validatesalesmaster`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function custvalidatetamil(data) {
  try {
    return await axios
      .post(`${baseUrl}/validatetamilsalesmaster`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function createcust(data) {
  try {
    return await axios
      .post(`${baseUrl}/createsalesmaster`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function updatecust(data) {
  try {
    return await axios
      .post(`${baseUrl}/updatesalesmaster`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        catcherror(error);
      });
  } catch (error) {
    catcherror(error);
  }
}

export async function listcust(type) {
    try {
      return await axios
        .get(`${baseUrl}/salesmasterlist/${type}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function listcustactive() {
    try {
      return await axios
        .get(`${baseUrl}/salesmasteractivelist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function viewcust(id,type) {
    try {
      return await axios
        .get(`${baseUrl}/viewsalesmaster/${id}/${type}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }

  export async function deletecust(data) {
    try {
      return await axios
        .post(`${baseUrl}/deletesalesmaster`, data)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }
  

  export async function listtypeactive() {
    try {
      return await axios
        .get(`${baseUrl}/consumertypelist`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          catcherror(error);
        });
    } catch (error) {
      catcherror(error);
    }
  }


function catcherror(error) {
  console.log(error.response)
  if (error.response.status == 401 ) {
    localStorage.clear();
    <Navigate to={"/login"} />;
  } else if (error.response.status == 422) {
    Swal.fire({
      title: "There are few errors occured while inserting/updating the data.",
      showCancelButton: false,
      confirmButtonColor: "#d71635",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //       window.location.reload();
    //   }
    });
  }  else if (error.response.status == 0) {
    toast.error(error.message);
  } else if (error.response.status == 500) {
    toast.error(error.response.statusText);
  } else if (
    error.response.status != 200 &&
    error.response.data.status == false &&
    error.response.data.code != 200
  ) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error(error.request.statusText);
  } else {
    toast.error(error.message);
  }
}
