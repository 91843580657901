import React, { useEffect, useState } from "react";
import { listdel } from "../../../../services/delivery";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import imageUrl from "../../../../ImageUrl";

export const ListDelivery = () => {
  const navigate = useNavigate();
  const columnDefs = [
    {
      headerName: "Action/செயல்",
      //headerTooltip "Action/செயல்",
      width: 100,
      field: "id",
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "center" },

      cellRenderer: function (params) {
        return (
          <a className="btn-sm btn-info" onClick={() => dellist(params.value)}>
            <i className="fa fa-eye fa-lg"></i>
          </a>
        );
      },
    },
    {
      headerName: "Photo",
      //headerTooltip "Photo",
      width: 100,
      field: "photo",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        if(params.value != null){
          return (
          <img src={imageUrl + path + params.value} width={"50px"} height={"50px"} onError={(e) => errorhandle(e)}/>
          );
        }
        
      },
    },
    {
      headerName: "Customer",
      //headerTooltip "Customer",
      width: 200,
      field: "name",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    // {
    //   headerName: "வாடிக்கையாளர்",
    //   //headerTooltip "வாடிக்கையாளர்",
    //   width: 200,
    //   field: "tamil_name",
    //   sortable: true,
    //   filter: true,
    //   floatingFilter: true,
    //   resizable: true,
    //   cellStyle: { textAlign: "left" },
    // },
    {
      headerName: "Date/தேதி",
      //headerTooltip "Date/தேதி",
      width: 130,
      field: "del_date",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: function (params) {
        return moment(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Type/வகை",
      //headerTooltip "Type/வகை",
      width: 100,
      field: "broiler_types",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
   
    {
      headerName: "Kilo/கிலோ",
      //headerTooltip "Kilo/கிலோ",
      width: 120,
      field: "chicken_kgs",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Rate/விலை",
      //headerTooltip "Rate/விலை",
      width: 100,
      field: "chicken_rate",
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount Type/தொகை வகை",
      //headerTooltip "Amount Type/தொகை வகை",
      width: 100,
      field: "amount_types",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Amount/தொகை",
      //headerTooltip "Amount/தொகை",
      width: 100,
      field: "amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Received/பெற்றது",
      //headerTooltip "Received/பெற்றது",
      width: 100,
      field: "rec_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Pending/நிலுவை",
      //headerTooltip "Pending/நிலுவை",
      width: 100,
      field: "pend_amount",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Description/விளக்கம்",
      //headerTooltip "Description/விளக்கம்",
      width: 120,
      field: "description",
      sortable: true,
      filter: false,
      floatingFilter: false,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    
  ];

  function dellist(did) {
    if (localStorage.getItem("Role") == "1") {
      navigate(`/superadmin/viewdelivery/${did}`);
    }else if (localStorage.getItem("Role") == "2") {
      navigate(`/manager/viewdelivery/${did}`);
    }
  }
  const [filterProduct, setFilterProduct] = useState([]);
  const [path, setPath] = useState("");

  useEffect(() => {
    listall();
  }, []);

  function listall() {
    setFilterProduct([]);
    listdel().then((res) => {
      if (res.status == true) {
        const sdata = res.data.details.filter((item) => {
          return item.pending_type == 0;
        })
        if(sdata !=undefined){
          setFilterProduct(sdata);
        }        
        setPath(res.data.path);
      }
    });
  }

  function errorhandle(ev) {
    ev.target.src = require("../../../../assets/img/user.png");
    ev.target.onerror = null;
  }

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-20">
        Delivery List / டெலிவரி பட்டியல்
      </h2>
      <Row className="row-sm mx-0 mt-3">
        <Col xxl={12} lg={12} md={12}>
              <div className="ag-theme-alpine ag-style">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={filterProduct}
                  pagination="true"
                  paginationPageSize="10"
                  suppressExcelExport={true}
                  suppressDragLeaveHidesColumns={true}
                />
              </div>
        </Col>
      </Row>
    </div>
  );
};
